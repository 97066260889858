import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { FileRejection } from "react-dropzone";

export interface RegisterPrivateLeaseBikeObject {
    code: string;
    contractNumber: string;
    frameNumber: string;
    accuNumber: string;
    emailAddressDealer: string;
    dateOfDelivery: string; // should this be a date :)
    channel: string;
}


export interface InvoiceUploadObject {
    code: string;
    contractNumber: string;
    dealerEmail: string;
    fileName?: string;
    data?: string; // byte
}

export enum InvoiceStatus {
    SENT = "SENT",
    FAIL_SENT = "FAIL_SENT",
    FAIL_VIRUS = "FAIL_VIRUS",
    NA = "NA"
}

export const InvoiceStatusDisplay: Readonly<Record<InvoiceStatus, string>> = {
    [InvoiceStatus.SENT]: "de factuur is verstuurd.",
    [InvoiceStatus.FAIL_SENT]: "de factuur is niet verwerkt.",
    [InvoiceStatus.FAIL_VIRUS]: "de factuur was niet veilig en is niet verwerkt.",
    [InvoiceStatus.NA]: "Geen factuur bijgevoegd",
};

export interface RegisterPrivateLeaseBikeObjectResponse
    extends Omit<FormRegisterPrivateLeaseBikeObject, "code" | "invoice">  {
    pickUpCode: string;
    notificationSent: boolean;
}

export interface InvoiceUploadObjectResponse {
    invoiceStatus?: InvoiceStatus;
}

export interface InvoiceUploadErrorObject {
    invoice?: FileRejection[];
}

export type RegisterPrivateLeaseBikeValidations = Partial<{
    code: Mary.organisms.ValidationFeedback;
    contractNumber: Mary.organisms.ValidationFeedback;
    frameNumber: Mary.organisms.ValidationFeedback;
    accuNumber: Mary.organisms.ValidationFeedback;
    emailAddressDealer: Mary.organisms.ValidationFeedback;
    dateOfDelivery: Mary.organisms.ValidationFeedback;
}>;

export enum ResponseStatus {
    "SUCCESS" = "SUCCESS",
    "INVALID" = "INVALID",
    "ERROR" = "ERROR",
}


export interface StatusObject {
    status: ResponseStatus;
}

export interface ErrorInfoObject {
    message: string;
    code: number;
}

export interface ErrorsObject {
    error: ErrorInfoObject[] | string;
}

export interface StatusCodeObject {
    statusCode: number;
}


export type FormRegisterPrivateLeaseBikeObject = Partial<RegisterPrivateLeaseBikeObject>;
export type FormRegisterPrivateLeaseBikeObjectResponse = RegisterPrivateLeaseBikeObjectResponse
& Partial<StatusObject & ErrorsObject & StatusCodeObject>;
export type FormInvoiceUploadObject = Partial<InvoiceUploadObject>;
export type FormInvoiceUploadErrorObject = Partial<InvoiceUploadErrorObject>;
export type FormInvoiceUploadObjectResponse = InvoiceUploadObjectResponse
& Partial<StatusObject & ErrorsObject & StatusCodeObject>;


