import { mapFormInvoiceUploadResponse,
    mapFormRegisterPrivateLeaseBikeResponse } from "./RegisterPrivateLeaseBike/RemoteConfig";
import { FormRegisterPrivateLeaseBikeObject,
    RegisterPrivateLeaseBikeObject } from "./RegisterPrivateLeaseBike/Types";
import { remoteClearError, remoteClearResponse } from "./RemoteActions";
import {
    RemoteConfig,
    RemoteScope,
} from "./RemoteTypes";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K>; } = {
    [RemoteScope.REGISTER_PRIVATE_LEASE_BIKE]: {
        scope: RemoteScope.REGISTER_PRIVATE_LEASE_BIKE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.REGISTER_PRIVATE_LEASE_BIKE));
            d(remoteClearError(RemoteScope.REGISTER_PRIVATE_LEASE_BIKE));
        },
        pathMapper: (_s, _ctx) => "/codes/register",
        bodyMapper: (_s, ctx) => {
            const formData: FormRegisterPrivateLeaseBikeObject = {};

            Object.keys(ctx).filter(f => f !== "file" && f !== "code" ).forEach((e) => {
                formData[e] = ctx?.[e as keyof RegisterPrivateLeaseBikeObject];
            });

            formData.code = ctx.code?.toLowerCase();
            return formData;
        },
        resMapper: mapFormRegisterPrivateLeaseBikeResponse,
    },
    [RemoteScope.ATTACH_INVOICE]: {
        scope: RemoteScope.ATTACH_INVOICE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ATTACH_INVOICE));
            d(remoteClearError(RemoteScope.ATTACH_INVOICE));
        },
        pathMapper: (_s, _ctx) => "/codes/upload",
        bodyMapper: (_s, ctx) => ctx,
        resMapper: mapFormInvoiceUploadResponse,
    },
};
