import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";

export const hasLengthValidation =
    (value: string | number, message: string, size?: number): Mary.organisms.ValidationFeedback => {
        if (!!size && typeof value === "string" && value && value.length !== size) {
            return {
                status: ValidationStatus.ERROR,
                message: Mary.utils.getText("app.07-organisms.form.field.utils.length--error",
                    "Please provide {{max}} characters.", {max: size.toString()}),
            };
        }

        return Mary.organisms.hasValueValidation(value, message);
    };

