/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from "axios";
import { FormInvoiceUploadObjectResponse, FormRegisterPrivateLeaseBikeObjectResponse } from "./Types";

export const mapFormRegisterPrivateLeaseBikeResponse =
    (resp: AxiosResponse): FormRegisterPrivateLeaseBikeObjectResponse => ({
        statusCode: resp?.status,
        ...resp.data as FormRegisterPrivateLeaseBikeObjectResponse,
    });

export const mapFormInvoiceUploadResponse =
    (resp: AxiosResponse): FormInvoiceUploadObjectResponse => ({
        statusCode: resp?.status,
        ...resp.data as FormInvoiceUploadObjectResponse,
    });

