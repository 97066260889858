import React from "react";
import { FormRegisterPrivateLeaseBikeObjectResponse } from "../../../../../store/RegisterPrivateLeaseBike/Types";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

interface Props {
    data: FormRegisterPrivateLeaseBikeObjectResponse;
}

export const Summary: React.FunctionComponent<Props> = ({ data }) => (
    <><Mary.base.Container
        theme={{
            shadow: Mary.theme.ThemeShadowSizes.SMALL,
            palette: Mary.theme.ThemePalette.CONTRAST_SECONDARY,
            padding: {
                "": { y: 3 },
                "sm": { y: 4 },
            },
            margin: {
                "": { b: 3 },
                "sm": { b: 4 },
            },
        }}
    >
        <Mary.base.Div className="scl-b-row">
            <Mary.base.Grid
                size={{ xs: 12, md: 6 }}
            >
                <Mary.organisms.FormField
                    label={Mary.utils.getText("app.form.code")}
                    asSummary
                    value={data.pickUpCode.toUpperCase()} />
            </Mary.base.Grid>
            <Mary.base.Grid
                size={{ xs: 12, md: 6 }}
            >
                <Mary.organisms.FormField
                    label={Mary.utils.getText("app.form.contractNumber")}
                    asSummary
                    value={data.contractNumber} />
            </Mary.base.Grid>
        </Mary.base.Div>
        <Mary.base.Div className="scl-b-row">
            <Mary.base.Grid
                size={{ xs: 12, md: 6 }}
            >
                <Mary.organisms.FormField
                    label={Mary.utils.getText("app.form.frameNumber")}
                    asSummary
                    value={data.frameNumber} />
            </Mary.base.Grid>
            <Mary.base.Grid
                size={{ xs: 12, md: 6 }}
            >
                <Mary.organisms.FormField
                    label={Mary.utils.getText("app.form.accuNumber")}
                    asSummary
                    value={data.accuNumber} />
            </Mary.base.Grid>
        </Mary.base.Div>
        <Mary.base.Div className="scl-b-row">
            <Mary.base.Grid
                size={{ xs: 12, md: 6 }}
            >
                <Mary.organisms.FormField
                    label={Mary.utils.getText("app.form.emailAddressDealer")}
                    asSummary
                    value={data.emailAddressDealer} />
            </Mary.base.Grid>
            <Mary.base.Grid
                size={{ xs: 12, md: 6 }}
            >
                <Mary.organisms.FormField
                    label={Mary.utils.getText("app.form.dateOfDelivery")}
                    type="date"
                    asSummary
                    value={data.dateOfDelivery} />
            </Mary.base.Grid>
        </Mary.base.Div>
    </Mary.base.Container>
    </>
);
