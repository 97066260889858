import * as React from "react";
import { Tooltip } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

interface OwnProps {
    label?: string;
    required?: boolean;
    asSummary?: boolean;
    title?: string;
}

/**
 *
 */
type Props = Mary.theme.ThemeConsumerProps & OwnProps & Mary.organisms.FieldCheckValidationProps;

/**
 *
 * @param props
 */
export const FormGroup: React.FunctionComponent<Props> = (props) => {
    const clazzName = Mary.utils.joinClasses(
        props.className || "",
        "scl-o-form__group",
        props.asSummary ? "scl-o-form__group--as-summary" : "",
    );

    return (
        <Mary.base.Div
            className={clazzName} transparent
            theme={{ paletteState: Mary.organisms.fieldValidationState(props) }}
        >
            {props.label &&
                <Mary.atoms.Label className={props.asSummary ? "scl-a-label__field--as-summary" : ""}>
                    {props.label}
                    {props.required ? <span className={"scl-a-label__field--as-required"}>*</span> : ""}
                    {props.title ?
                        <Mary.base.Div className="scl-a-tooltip__wrapper">
                            <Mary.atoms.Icon name="info" className="scl-a-tooltip__trigger"/>
                            <Tooltip>{props.title}</Tooltip>
                        </Mary.base.Div>
                        : null}
                </Mary.atoms.Label>
            }
            {props.children}
        </Mary.base.Div>
    );
};
