export const getBase64 = (file: File) => new Promise((resolve, reject) => {
    let baseURL = "";
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    reader.onerror = (e) => {
        reject(e);
    };

    reader.onabort = (e) => {
        reject(e);
    };

    // on reader load somthing...
    reader.onload = (e) => {
        if (e.target) {
            baseURL = e.target.result as string;
            resolve(baseURL);
        }
    };
});
