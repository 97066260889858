/* eslint-disable max-len */
import React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { Tabs } from "../00-blocks/Tabs";
import { PageTabs } from "../../../../store/AppDisplays";
import { OrderedSet } from "immutable";
import { TabsContext } from "../00-blocks/TabsContext";

interface Props {
    asPage: boolean;
}

export const RegisterPrivateLeaseBike: React.FC<Props> = (props) => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    const contract_number = url.searchParams.get("contract_number");
    const [currentTab, setCurrentTab] = React.useState<PageTabs>(!!code && !!contract_number ? PageTabs.ATTACH_INVOICE : PageTabs.REGISTER);
    const [formData, setFormData] = React.useState<{ code: string; contractNumber: string; dealerEmail: string; isDeliveredOnline?: string }>({ code: "", contractNumber: "", dealerEmail: "", isDeliveredOnline: undefined });
    const [isInvoiceBtnClicked, setIsInvoiceBtnClicked] = React.useState(false);
    const [isSubmitClicked, setIsSubmitClicked] = React.useState(false);

    const changeTab = (newTab: PageTabs) => {
        setCurrentTab(newTab);
    };

    const handleInputChange = (key: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const handleBtnClick = () => {
        setIsInvoiceBtnClicked(true);
    };

    const handleSubmitClick = () => {
        setIsSubmitClicked(true);
    };

    const contextValue = {
        changeTab: changeTab,
        currentTab: currentTab,
        formData: formData,
        handleInputChange: handleInputChange,
        isInvoiceBtnClicked: isInvoiceBtnClicked,
        handleBtnClick: handleBtnClick,
        isSubmitClicked: isSubmitClicked,
        handleSubmitClick: handleSubmitClick,
    };
    return (
        <Mary.base.Container
            theme={{
                shadow: props.asPage ? Mary.theme.ThemeShadowSizes.SMALL : undefined,
                palette: props.asPage ? Mary.theme.ThemePalette.CONTRAST_PRIMARY : undefined,
                padding: {
                    "": { y: 3 },
                    "sm": { y: 4 },
                },
            }}
        >
            <TabsContext.Provider value={contextValue}>
                <Tabs
                    tabs={OrderedSet([PageTabs.REGISTER, PageTabs.ATTACH_INVOICE])}
                    defaultTab={currentTab}
                />
            </TabsContext.Provider>
        </Mary.base.Container>
    );
};
