import { createContext } from "react";
import { PageTabs } from "../../../../store/AppDisplays";

interface TabsContextProps {
    changeTab: (newTab: PageTabs) => void;
    currentTab: PageTabs;
    formData: { code: string; contractNumber: string; dealerEmail: string; isDeliveredOnline?: string };
    handleInputChange: (key: string, value: string) => void;
    isInvoiceBtnClicked: boolean;
    handleBtnClick: () => void;
    isSubmitClicked: boolean;
    handleSubmitClick: () => void;
}

export const TabsContext = createContext<TabsContextProps | undefined>(undefined);
