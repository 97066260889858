/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable max-len */

import React from "react";
import {
    FormInvoiceUploadErrorObject,
    FormRegisterPrivateLeaseBikeObject,
    RegisterPrivateLeaseBikeValidations} from "../../../../../store/RegisterPrivateLeaseBike/Types";
import { hasLengthValidation } from "../../../07-organisms/Form/Field.utils";
import { pickUpCode } from "../../../../utils/FormatDisplays";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";
import { Required } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/Required";
import { FormField } from "../../../07-organisms/Form/Field";
import PrivacyStatement from "../../../../../Docs/PrivacyStatement.pdf";
import { TabsContext } from "../../00-blocks/TabsContext";

interface Props {
    updateStore: (
        form?: FormRegisterPrivateLeaseBikeObject,
        errors?: FormInvoiceUploadErrorObject) => void;
    form: FormRegisterPrivateLeaseBikeObject;
    errors: FormInvoiceUploadErrorObject;
    validations: RegisterPrivateLeaseBikeValidations;
    onSubmit: (body: FormRegisterPrivateLeaseBikeObject) => void;
    onClear: () => void;
    setDeliveredOnline: (deliveredOnline: boolean) => void;
    handleInputChange: (key: string, value: string) => void;
}

interface State {
    deliveredOnline?: boolean;
}

const isDisabled = (state: FormRegisterPrivateLeaseBikeObject) => {
    const fields = Object.keys(state) as Array<keyof FormRegisterPrivateLeaseBikeObject>;

    // TODO: Fix this shit @nick
    if (fields.length < 6) {
        return true;
    }

    const allFieldsValid =  fields.every(k => {
        if (k === "emailAddressDealer") {
            return Mary.organisms.hasEmailValidation(state[k] as string | number ?? "", "")?.status === ValidationStatus.SUCCESS;
        }
        if (k === "code") {
            return hasLengthValidation(state[k] as string | number ?? "", "", 9)?.status === ValidationStatus.SUCCESS;
        }
        return  Mary.organisms.hasValueValidation(state[k] as string | number ?? "", "")?.status === ValidationStatus.SUCCESS;
    });

    return !allFieldsValid;
};

export const setIframeHeight = () => {
    window.parent.postMessage(
        {
            type: "claude:bikes:height-changed",
            data: {
                height: 500,
            },
        },
        "*",
    );
};

export class Form
    extends React.Component<Props, State> {

    static contextType = TabsContext;

    public constructor(props: Props) {
        super(props);

        this.state = { deliveredOnline: undefined };
    }

    public render() {
        const {updateStore, handleInputChange, form, setDeliveredOnline, onClear, onSubmit, validations } = this.props;
        return (
            <>
                <Mary.base.Div className="scl-b-row">
                    <Mary.base.Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label={Mary.utils.getText("app.form.code")}
                            required
                            // eslint-disable-next-line max-len
                            title="Vul hier de afhaalcode in die de klant heeft ontvangen in de email van Volkswagen Pon Financial Services."
                            onChange={(value?: string | number) => {
                                updateStore({ code: pickUpCode(value?.toString() ?? "") });
                                handleInputChange("code", pickUpCode(value?.toString() ?? ""));
                            }}
                            value={form.code}
                            validationFunction={
                                (value: string | number) =>
                                    hasLengthValidation(pickUpCode(value.toString() ?? ""),
                                        "Afhaalcode ingevuld.", 9)}
                        />
                    </Mary.base.Grid>
                    <Mary.base.Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <Mary.organisms.FormField
                            label={Mary.utils.getText("app.form.contractNumber")}
                            required
                            onChange={(value?: string | number) => {
                                updateStore({ contractNumber: value?.toString() ?? "" });
                                handleInputChange("contractNumber", value?.toString() ?? "");
                            }}
                            value={form.contractNumber}
                            validationFunction={
                                (value: string | number) =>
                                    Mary.organisms.hasValueValidation(value,
                                        "Contractnummer ingevuld.")}
                        />
                    </Mary.base.Grid>
                </Mary.base.Div>
                <Mary.base.Div className="scl-b-row">
                    <Mary.base.Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <Mary.organisms.FormField
                            label={Mary.utils.getText("app.form.frameNumber")}
                            required
                            onChange={(value?: string | number) => {
                                console.log("frameNumber:", value);
                                updateStore({ frameNumber: value?.toString().replace(/\s+/g, "") ?? "" });
                            }}
                            value={form.frameNumber}
                            validationFunction={
                                (value: string | number) =>
                                    Mary.organisms.hasValueValidation(value,
                                        "Framenummer ingevuld.")}
                        />
                    </Mary.base.Grid>
                    <Mary.base.Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <Mary.organisms.FormField
                            label={Mary.utils.getText("app.form.accuNumber")}
                            required
                            onChange={(value?: string | number) =>
                                updateStore({ accuNumber: value?.toString() ?? "" })}
                            value={form.accuNumber}
                            validationFunction={
                                (value: string | number) =>
                                    Mary.organisms.hasValueValidation(value,
                                        "Accunummer ingevuld.")}
                        />
                    </Mary.base.Grid>
                </Mary.base.Div>
                <Mary.base.Div className="scl-b-row">
                    <Mary.base.Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <Mary.organisms.FormField
                            label={Mary.utils.getText("app.form.emailAddressDealer")}
                            type="email"
                            required
                            onChange={(value?: string | number) => {
                                updateStore({ emailAddressDealer: value?.toString() ?? "" });
                                handleInputChange("dealerEmail", value?.toString() ?? "");
                            }}
                            value={form.emailAddressDealer}
                            aria-invalid
                            validationFunction={
                                (value: string | number) =>
                                    Mary.organisms.hasEmailValidation(value,
                                        "E-mailadres dealer ingevuld.")}
                        />
                    </Mary.base.Grid>
                    <Mary.base.Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label={Mary.utils.getText("app.form.dateOfDelivery")}
                            type="date"
                            required
                            // eslint-disable-next-line max-len
                            title="Vul hier de exacte datum van uitlevering in. Het contract zal starten op de door u ingegeven datum. De afleverdatum mag niet in de toekomst liggen."
                            maxDate={new Date(Date.now())}
                            onChange={(value?: string | number) =>
                                updateStore({ dateOfDelivery: value?.toString() ?? "" })}
                            value={form.dateOfDelivery}
                            validationFunction={
                                (value: string | number) =>
                                    Mary.organisms.hasValueValidation(value,
                                        "Datum van aflevering aan klant ingevuld.")}
                        />
                    </Mary.base.Grid>
                </Mary.base.Div>
                <Mary.atoms.Label>
                    {Mary.utils.getText("app.form.deliveredFrom", "Where was the lease application submitted?")}
                </Mary.atoms.Label>
                <Mary.base.Div className="scl-b-row">
                    <Mary.base.Grid
                        size={{ xs: 12, md: 12 }}
                        theme={{
                            padding: {
                                "": { b: 3, t: 1 },
                                "md": { b: 4, t: 2 },
                            },
                        }}
                    >
                        <Mary.atoms.radiobtn.RadioBtn
                            checked={this.state.deliveredOnline === undefined
                                ? false
                                : !this.state.deliveredOnline}
                            label={"Via de website door de consument"}
                            onChange={(value) => {
                                this.setState({ deliveredOnline: value === "No" ? false : true});
                                setDeliveredOnline(value === "No" ? false : true);
                                handleInputChange("isDeliveredOnline", value === "No" ? "false" : "true");
                                updateStore({ channel: "consumer" });
                            }}
                            name={"deliveredFrom"}
                            value={"No"} />
                        <Mary.base.Div>
                            <Mary.atoms.radiobtn.RadioBtn
                                checked={this.state.deliveredOnline ?? false}
                                label={"Via de dealerportal door jou als dealer"}
                                onChange={(value) => {
                                    this.setState({ deliveredOnline: value === "Yes" ? true : false});
                                    setDeliveredOnline(value === "Yes" ? true : false);
                                    handleInputChange("isDeliveredOnline", value === "Yes" ? "true" : "false");
                                    updateStore({ channel: "dealer" });
                                }}
                                name={"deliveredFrom"}
                                value={"Yes"} />
                        </Mary.base.Div>
                    </Mary.base.Grid>
                </Mary.base.Div>
                <Mary.base.Div theme={{margin: {
                    "": { t: -1, b: 2 },
                    "sm": { t: -1, b: 3 },
                }}}>
                    <Mary.atoms.Blockquote>
Als je hier gegevens invult, ga je ermee akkoord dat we deze gegevens verwerken conform ons privacyreglement. Voor een andere toelichting verwijzen we je naar het <a href={PrivacyStatement} download={"privacy-statement"}>privacy statement</a>.
                    </Mary.atoms.Blockquote>

                    <div className="scl-h-text-align--right"><Required /></div>
                </Mary.base.Div>
                {Object.keys(validations).some(v => validations[v as keyof RegisterPrivateLeaseBikeValidations]?.status === ValidationStatus.ERROR ) && (
                    <Mary.base.Div className="scl-b-row"
                        theme={{margin: {
                            "": { b: 2 },
                            "sm": {b: 3 },
                        }}}
                    >
                        <Mary.atoms.Blockquote
                            theme={{
                                paletteState: Mary.theme.ThemePaletteState.DANGER}}
                            background
                            className="scl-h-foreground--contrast-state-danger"
                            style={{position: "relative"}}>
                            <h5><Mary.atoms.Icon style={{
                                fontSize: ".75em",
                                verticalAlign: "middle",
                                marginRight: "5px",
                            }} name={"exclamation-triangle"}/>&nbsp;Niet alle velden zijn correct ingevuld!</h5>
                            <p>Om verder te gaan moeten de volgende velden (goed) ingevuld zijn.</p><br/>
                            <ul>
                                {Object.keys(validations).filter(f => validations[f as keyof RegisterPrivateLeaseBikeValidations]?.status === ValidationStatus.ERROR).map((k, index) => (
                                    <li key={`${k}-${index}}`}>
                                        <Mary.atoms.Icon style={{
                                            fontSize: "8px",
                                            verticalAlign: "middle",
                                            marginRight: "5px",
                                            marginLeft: "10px",
                                        }} name={"circle"}/>&nbsp;{Mary.utils.getText(
                                            `app.form.${k}`)}
                                    </li>
                                )
                                )}
                            </ul>
                            <Mary.base.Div style={{
                                position: "absolute",
                                top: "100%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                borderWidth: "8px",
                                borderStyle: "solid",
                                fontSize: "0",
                                borderColor: "var(--scl-color-state-brand--danger) transparent transparent transparent",
                            }}>&nbsp;</Mary.base.Div>
                        </Mary.atoms.Blockquote>
                    </Mary.base.Div>
                )}
                <Mary.molecules.ButtonsWrapper>
                    <Mary.atoms.button.Button
                        className="scl-a-btn--big"
                        theme={{
                            palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                        }}
                        link={{
                            onClick: () => {
                                setIframeHeight();
                                onSubmit(form);
                                this.context.handleSubmitClick();
                            }}}
                        disabled={isDisabled(form)}
                    >Versturen
                    </Mary.atoms.button.Button>
                    <Mary.atoms.button.Button
                        className="scl-a-btn--big"
                        theme={{
                            palette: Mary.theme.ThemePalette.CONTRAST_SECONDARY,
                        }}
                        link={{
                            onClick: () =>
                                onClear(),
                        }}
                    >Begin opnieuw
                    </Mary.atoms.button.Button>
                </Mary.molecules.ButtonsWrapper>
            </>
        );
    }
}

export const testUtil = {
    isDisabled: isDisabled,
};
