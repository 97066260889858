import { Div } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/03-base";
import { IconNames, Heading, Headings } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";
import { Button } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/Button";
import { RTE } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms";
import { ThemePalette } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/theme";
import { I18nTextProps, getText } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/utils";
import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
    title: string | I18nTextProps;
    description?: string | I18nTextProps;
    goBack?: string;
}

export const Header: React.FunctionComponent<Props> = props => (
    <Div
        theme={{
            padding: {
                "": { b: 3 },
                "sm": { b: 3 },
            },
        }}
    >

        <Div style={{ display: "flex", flexDirection: "row"}}>

            { props.goBack &&
                <Div
                    style={{flex: 1, flexGrow: 0}}
                    theme={{
                        padding: {
                            "": { r: 2 },
                            "sm": { r: 3 },
                        },
                    }}
                >
                    <NavLink
                        to={props.goBack}
                        exact
                    >
                        <Button icon={IconNames.ARROW_LEFT} theme={{palette: ThemePalette.BRAND_ACCENT}}/>
                    </NavLink>
                </Div>
            }
            <Div
                style={{flex: 1, alignSelf: "center"}}
            >
                {props.goBack ?
                    <Heading level={Headings.H1} headingStyle={Headings.H4}>{getText(props.title)}</Heading>
                    :
                    <Heading
                        level={Headings.H1}
                        headingStyle={Headings.H1}
                        className="scl-h-foreground--brand-text-heading">{getText(props.title)}</Heading>
                }
                { props.description &&
                    <Div
                        theme={{
                            padding: {
                                "": { t: 1 },
                                "sm": { t: 1 },
                            },
                        }}
                    >
                        <RTE>{getText(props.description)}</RTE>
                    </Div>
                }
            </Div>
        </Div>
    </Div>
);
