/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState } from "react";
import { OrderedSet } from "immutable";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { PageTabs, PageTabsDisplay } from "../../../../store/AppDisplays";
import { TabsContext } from "./TabsContext";

interface TabsProps {
    tabs: OrderedSet<PageTabs>;
    defaultTab: PageTabs;
}

export const Tabs: React.FC<TabsProps> = ({ tabs, defaultTab }) => {
    const [currentTab, setCurrentTab] = useState<PageTabs>(defaultTab);
    const [formData, setFormData] = useState<{ code: string; contractNumber: string; dealerEmail: string; isDeliveredOnline?: string }>({ code: "", contractNumber: "", dealerEmail: "", isDeliveredOnline: undefined });
    const [isInvoiceBtnClicked, setIsInvoiceBtnClicked] = useState(false);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const changeTab = (newTab: PageTabs) => {
        setCurrentTab(newTab);
    };

    const handleInputChange = (key: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const handleBtnClick = () => {
        setIsInvoiceBtnClicked(true);
    };

    const handleSubmitClick = () => {
        setIsSubmitClicked(true);
    };

    const contextValue = {
        changeTab: changeTab,
        currentTab: currentTab,
        formData: formData,
        handleInputChange: handleInputChange,
        isInvoiceBtnClicked: isInvoiceBtnClicked,
        handleBtnClick: handleBtnClick,
        isSubmitClicked: isSubmitClicked,
        handleSubmitClick: handleSubmitClick,
    };

    const getItems = (tabs: OrderedSet<PageTabs>): Mary.organisms.menu.MenuItem[] => tabs
        .map((tab) => ({
            disabled: !PageTabsDisplay[tab].component || (tab === PageTabs.ATTACH_INVOICE && (formData.isDeliveredOnline === "false" || formData.isDeliveredOnline === undefined) && isSubmitClicked),
            children: PageTabsDisplay[tab].title,
            link: { onClick: () => changeTab(tab) },
            active: currentTab === tab,
        })
        )
        .toArray();

    return (
        <TabsContext.Provider value={contextValue}>
            <>
                <Mary.base.Div
                    theme={{
                        padding: {
                            "": { b: 3 },
                            "sm": { b: 4 },
                        },
                    }}
                >
                    <Mary.organisms.menu.Menu
                        orientation={Mary.organisms.menu.MenuOrientation.HORIZONTAL}
                        items={getItems(tabs)}
                    />
                </Mary.base.Div>
                <Mary.base.Div
                    theme={{
                        padding: {
                            "": { b: 3 },
                            "sm": { b: 4 },
                        },
                    }}
                >
                    {currentTab && PageTabsDisplay[currentTab]?.component && (
                        React.createElement(
                            PageTabsDisplay[currentTab].component
                        )
                    )}
                </Mary.base.Div>
            </>
        </TabsContext.Provider>
    );
};
