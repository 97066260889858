/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../../../store/ReduxState";
import { RegisterPrivateLeaseBike } from "./RegisterPrivateLeaseBike";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

interface OwnProps {
    asPage: boolean;
}

interface StateProps {
}

type Props = StateProps & OwnProps;

/**
 *
 */
export const mapStateToProps = (_s: ReduxState): StateProps => ({
});

class HomeComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.setIframeHeight = this.setIframeHeight.bind(this);
        this.inIframe = this.inIframe.bind(this);
    }

    public componentDidMount() {
        if(this.inIframe()) {
            window.addEventListener("load", this.setIframeHeight, false);
            window.addEventListener(
                "resize", this.setIframeHeight, false);
            this.setIframeHeight("init");
        }
    }

    public componentWillUnmount() {
        if(this.inIframe()) {
            window.removeEventListener("load", this.setIframeHeight, false);
            window.removeEventListener(
                "resize", this.setIframeHeight, false);
        }
    }

    public render() {
        return (
            <Mary.base.Div
                theme={{
                    padding: {
                        "": { b: 3 },
                        "sm": { b: 4 },
                    },
                }}
                style={{ width: "100%"}}
            >
                <Mary.molecules.EnvIndicatorBar />
                <RegisterPrivateLeaseBike asPage={this.props.asPage} />
            </Mary.base.Div>
        );
    }

    private inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };

    private setIframeHeight(event: any) {
        const height = document.documentElement.scrollHeight;
        console.log(height, "height", event);
        window.parent.postMessage(
            {
                type: "claude:bikes:height-changed",
                data: {
                    height: height,
                },
            },
            "*",
        );
    }
}

export const Home = connect(
    mapStateToProps,
    undefined,
)(HomeComp);
