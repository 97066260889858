import { Register } from "../App/components/09-views/00-blocks/FormTabs/Register";
import { Invoice } from "../App/components/09-views/00-blocks/FormTabs/AttachInvoice";
/**
 *
 */
export type DisplayOf<T extends string | number> = Readonly<Record<T, string>>;

/**
 *
 */

/**
 *
 */
export enum Pages { }

/**
 *
 */
export enum PageTabs {
    REGISTER = "REGISTER",
    ATTACH_INVOICE = "ATTACH_INVOICE",
}

export interface Tab {
    // key: string;
    title: string;
    description: string;
    component?: React.FunctionComponent | React.ComponentState;
}

export interface DynamicTab {
    title: string;
    results: Object;
    // schema: OAS.Schema;
}

export type TabRef = Record<string, DynamicTab>;

export interface Page {
    title: string;
    description: string;
    path: string;
    component?: React.FunctionComponent | React.ComponentState;
    beta?: boolean;
    // parameters?: OAS.Parameter[];
    pagination?: boolean;
    // responses?: OAS.Responses;
    parent?: Page;
}

export type PageRef = Record<string, Page>;

/**
 *
 */
export const PageDisplay: Readonly<Record<Pages, Page>> = {
};

/**
 *
 */
export const PageTabsDisplay: Readonly<Record<PageTabs, Tab>> = {
    [PageTabs.REGISTER]: {
        title: "Aanmelden",
        description: "You can register your bike here",
        component: Register,
    },
    [PageTabs.ATTACH_INVOICE]: {
        title: "Factuur bijvoegen",
        description: "You can upload an invoice here",
        component: Invoice,
    },
};
