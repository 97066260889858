/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";
import { ThemePalette } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/theme";
import React from "react";
import { ErrorCode } from "react-dropzone";
import { getBase64 } from "../../../../utils/upload";
import { DragNDrop, FileErrorMessageDisplay } from "../../../07-organisms/DragNDrop";
import {
    ErrorInfoObject,
    FormInvoiceUploadErrorObject,
    FormInvoiceUploadObject,
    FormInvoiceUploadObjectResponse,
    ResponseStatus} from "../../../../../store/RegisterPrivateLeaseBike/Types";
import { FormStatus, PageHeader, setIframeHeight } from "../../02-pages/RegisterPrivateLeaseBike/index";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { FormField } from "../../../07-organisms/Form/Field";
import { connect } from "react-redux";
import { pickUpCode } from "../../../../utils/FormatDisplays";
import { hasLengthValidation } from "../../../07-organisms/Form/Field.utils";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { TabsContext } from "../TabsContext";
import { FormGroup } from "../../../07-organisms/Form/Group";

interface State {
    invoice: FormInvoiceUploadObject;
    errors: FormInvoiceUploadErrorObject;
    status: FormStatus;
}

interface StateProps {
    results?: FormInvoiceUploadObjectResponse;
    loading: boolean;
}

/**
 *
 */
interface DispatchProps {
    onSubmit: (body: FormInvoiceUploadObject) => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    results: s.prop("remote").prop(RemoteScope.ATTACH_INVOICE),
    loading: isRemoteLoading(s, RemoteScope.ATTACH_INVOICE),
});

/**
 *
 */
const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    onSubmit: (body) =>
        dispatch(remoteTrigger(RemoteScope.ATTACH_INVOICE, body)),
    clearResults: () => (
        (
            dispatch(remoteClearResponse(RemoteScope.ATTACH_INVOICE)),
            dispatch(remoteClearError(RemoteScope.ATTACH_INVOICE))
        )),
});

const isDisabled = (state: FormInvoiceUploadObject) => {
    const fields = Object.keys(state) as Array<keyof FormInvoiceUploadObject>;

    if (fields.length < 5) {
        return true;
    }

    const allFieldsValid =  fields.every(k => {
        if (k === "dealerEmail") {
            return Mary.organisms.hasEmailValidation(state[k] as string | number ?? "", "")?.status === ValidationStatus.SUCCESS;
        }
        if (k === "code") {
            return hasLengthValidation(state[k] as string | number ?? "", "", 9)?.status === ValidationStatus.SUCCESS;
        }
        return  Mary.organisms.hasValueValidation(state[k] as string | number ?? "", "")?.status === ValidationStatus.SUCCESS;
    });

    return !allFieldsValid;
};

class InvoiceComp
    extends React.Component<Props, State> {

    static contextType = TabsContext;

    public constructor(props: Props) {
        super(props);

        this.state = {
            invoice: {},
            errors: {},
            status: FormStatus.INIT_UPLOAD,
        };

        this.updateStore = this.updateStore.bind(this);
        this.hasError = this.hasError.bind(this);
        this.hasErrorOrInvalid = this.hasErrorOrInvalid.bind(this);
        this.showForm = this.showForm.bind(this);
        this.showSummary = this.showSummary.bind(this);
        this.showError = this.showError.bind(this);
        this.retry = this.retry.bind(this);
    }

    public componentDidMount() {
        const { formData } = this.context;
        const url = new URL(window.location.href);
        const code = pickUpCode(url.searchParams.get("code") ?? "");
        const contract_number = url.searchParams.get("contract_number");
        const dealerEmail = url.searchParams.get("email") ?? "";
        if(this.context.isInvoiceBtnClicked) {
            this.setState({ invoice: { fileName: "", data: "", code: formData.code, contractNumber: formData.contractNumber, dealerEmail: formData.dealerEmail }});
        } else if (code && contract_number) {
            this.setState({ invoice: { fileName: "", data: "", code: code, contractNumber: contract_number, dealerEmail: dealerEmail }});
        } else {
            this.setState({ invoice: { fileName: "", data: "", code: "", contractNumber: "", dealerEmail: "" }});
        }
        this.props.clearResults();
    }

    public componentDidUpdate(_prev: Props) {
        if (this.hasErrorOrInvalid() && this.state.status !== FormStatus.ERROR) {
            this.setState({
                ...this.state,
                status: FormStatus.ERROR,
            });
        } else if ((this.showSummary()) && this.state.status !== FormStatus.SUCCESS) {
            this.setState({
                ...this.state,
                status: FormStatus.SUCCESS,
            });
        }
    }


    public render() {
        const { invoice, errors } = this.state;
        const url = new URL(window.location.href);
        const code = url.searchParams.get("code");
        const contract_number = url.searchParams.get("contract_number");
        const isReadonlyAndDisabled = this.context.isInvoiceBtnClicked || (!!code && !!contract_number);

        const validateInvoiceFile: (value: string | number) => Mary.organisms.ValidationFeedback = (_v) => {
            if (errors?.invoice) {
                return ({
                    status: ValidationStatus.ERROR,
                    message: (
                        errors?.invoice?.map((f, index) => {
                            const name = f.file.name;
                            return (
                                <div key={`${name}-${index}`}>
                                    <b>{name}:</b>
                                    {f.errors.map(e => FileErrorMessageDisplay[e.code as ErrorCode]).join(",")}<br/>
                                </div>
                            );
                        })
                    ),
                });
            }
            if (invoice.data && invoice.fileName) {
                return ({
                    status: ValidationStatus.SUCCESS,
                    message: "Factuur toegevoegd.",
                });
            } else {
                return ({
                    status: ValidationStatus.DEFAULT,
                });
            }
        };
        const invoiceFileProps = {
            value: invoice.data && invoice.fileName || errors?.invoice ? "yes" : undefined,
            validationFunction: validateInvoiceFile,
        };

        return (
            <>
                {this.props.loading ?
                    (
                        <Mary.atoms.loadingindicator.LoadingIndicator
                            type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                            theme={{ palette: Mary.theme.ThemePalette.BRAND_PRIMARY }}
                        />
                    ) : (
                        <>
                            {<PageHeader status={this.state.status} tab="upload-invoice"/>}
                            {this.showError()}
                            {this.showForm() ? (
                                <>
                                    <Mary.base.Div className="scl-b-row">
                                        <Mary.base.Grid
                                            size={{ xs: 12, md: 6 }}
                                        >
                                            <FormField
                                                label={Mary.utils.getText("app.form.code")}
                                                required
                                                // eslint-disable-next-line max-len
                                                title="Vul hier de afhaalcode in die de klant heeft ontvangen in de email van Volkswagen Pon Financial Services."
                                                readonly={isReadonlyAndDisabled}
                                                disabled={isReadonlyAndDisabled}
                                                onChange={(value?: string | number) => {
                                                    this.updateStore({ code: pickUpCode(value?.toString() ?? "") });
                                                }}
                                                value={this.state.invoice.code}
                                                validationFunction={(value: string | number) => hasLengthValidation(pickUpCode(value.toString() ?? ""),
                                                    "Afhaalcode ingevuld.", 9)} />
                                        </Mary.base.Grid>
                                        <Mary.base.Grid
                                            size={{ xs: 12, md: 6 }}
                                        >
                                            <Mary.organisms.FormField
                                                label={Mary.utils.getText("app.form.contractNumber")}
                                                required
                                                readonly={isReadonlyAndDisabled}
                                                disabled={isReadonlyAndDisabled}
                                                onChange={(value?: string | number) => this.updateStore({ contractNumber: value?.toString() ?? "" })}
                                                value={this.state.invoice.contractNumber}
                                                validationFunction={(value: string | number) => Mary.organisms.hasValueValidation(value,
                                                    "Contractnummer ingevuld.")} />
                                        </Mary.base.Grid>
                                    </Mary.base.Div>
                                    <Mary.base.Div className="scl-b-row">
                                        <Mary.base.Grid
                                            size={{ xs: 12, md: 6 }}
                                        >
                                            <Mary.organisms.FormField
                                                label={Mary.utils.getText("app.form.emailAddressDealer")}
                                                type="email"
                                                required
                                                onChange={(value?: string | number) =>
                                                    this.updateStore({ dealerEmail: value?.toString() ?? "" })}
                                                value={this.state.invoice.dealerEmail}
                                                aria-invalid
                                                validationFunction={
                                                    (value: string | number) =>
                                                        Mary.organisms.hasEmailValidation(value,
                                                            "E-mailadres dealer ingevuld.")}
                                            />
                                        </Mary.base.Grid>
                                    </Mary.base.Div>
                                    <FormGroup
                                        {...invoiceFileProps}
                                    >
                                        <Mary.base.Div
                                            theme={{
                                                padding: {
                                                    "": { t: 1, b: 3 },
                                                    "sm": { t: 1, b: 3 },
                                                },
                                            }}
                                        >
                                            <span>
                                                <Mary.atoms.Label>
                                                    <span style={{ whiteSpace: "pre-line" }}>
                                                        {"Let op: het is van belang dat de factuur op naam staat van Volkswagen Pon Financial Services (zie hieronder volledige factuurgegevens). Vermeld daarnaast het juiste BTW percentage van 21% op de factuur."}
                                                        <br />
                                                        {`
                                Volkswagen Pon Financial Services B.V.
                                T.a.v. de crediteurenadministratie
                                Postbus 617
                                3800 AP AMERSFOORT
                                Nederland`}
                                                    </span>
                                                </Mary.atoms.Label>
                                            </span>
                                        </Mary.base.Div>
                                        {validateInvoiceFile(invoiceFileProps.value ?? "").status === ValidationStatus.SUCCESS ? (
                                            <Mary.base.Div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderBottom: "1px solid var(--scl-color-state-brand--success)",
                                                    marginBottom: "30px",
                                                }}
                                                theme={{
                                                    padding: {
                                                        "": { b: 2 },
                                                    },
                                                }}>
                                                <Mary.base.Div
                                                    style={{
                                                        flex: "1",
                                                    }}
                                                    theme={{
                                                        padding: {
                                                            "": { t: 2 },
                                                        },
                                                    }}>
                                                    <Mary.organisms.RTE theme={{ palette: ThemePalette.BRAND_PRIMARY }}>
                                                        <span className="scl-h-foreground--brand-text-body">
                                                            <>{invoice?.fileName}&nbsp;</></span>
                                                    </Mary.organisms.RTE>
                                                </Mary.base.Div>
                                                <Mary.base.Div
                                                    style={{
                                                        flex: "0 1 auto",
                                                        alignSelf: "center",
                                                        height: "33px",
                                                    }}>
                                                    <Mary.atoms.button.Button
                                                        icon={Mary.atoms.IconNames.DELETE}
                                                        theme={{ paletteState: Mary.theme.ThemePaletteState.DANGER }}
                                                        style={{
                                                            transform: "scale(.75) translateX(5.5px)",
                                                        }}
                                                        link={{
                                                            onClick: () => this.updateStore({ fileName: undefined, data: undefined }, { invoice: undefined }),
                                                        }} />
                                                </Mary.base.Div>
                                            </Mary.base.Div>
                                        ) : (
                                            <Mary.base.Div theme={{
                                                margin: {
                                                    "": { b: 4 },
                                                },
                                            }}>
                                                <DragNDrop
                                                    onDropRejected={(e) => {
                                                        this.updateStore({ fileName: undefined, data: undefined }, { invoice: e });
                                                    }}
                                                    onDropAccepted={(e) => {
                                                        getBase64(e[0])
                                                            .then((result) => {
                                                                if (e[0] && e[0].name && result) {
                                                                    this.updateStore({
                                                                        fileName: e[0].name,
                                                                        data: result as string,
                                                                    }, { invoice: undefined });
                                                                } else {
                                                                    this.updateStore({ fileName: undefined, data: undefined }, {
                                                                        invoice: [{
                                                                            file: e[0],
                                                                            errors: [{
                                                                                message: "No File present",
                                                                                code: ErrorCode.FileInvalidType,
                                                                            }],
                                                                        }],
                                                                    });
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                console.log("what is going wrong", err);
                                                                this.updateStore({ fileName: undefined, data: undefined }, {
                                                                    invoice: [{
                                                                        file: e[0],
                                                                        errors: [{
                                                                            message: "Can't generate file.",
                                                                            code: ErrorCode.FileInvalidType,
                                                                        }],
                                                                    }],
                                                                });
                                                            });
                                                    }}
                                                    maxFiles={1}
                                                    maxSize={10000000}
                                                    accept={".pdf"} />
                                            </Mary.base.Div>
                                        )}
                                        {Mary.organisms.fieldValidationMessage(invoiceFileProps) &&
                            (
                                <div className="scl-o-form__info">
                                    <div className="scl-o-form__info-text">
                                        {Mary.organisms.fieldValidationMessage(invoiceFileProps)}
                                    </div>
                                    <div className="scl-o-form__info-icon">
                                        {Mary.organisms.fieldValidationIcon(invoiceFileProps)}
                                    </div>
                                </div>
                            )}
                                    </FormGroup>
                                    <Mary.base.Div style={{display: "flex", marginTop: "10px", justifyContent: "flex-end"}}>
                                        <Mary.atoms.button.Button
                                            className="scl-a-btn--big"
                                            theme={{
                                                palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                            }}
                                            link={{
                                                onClick: () => {
                                                    setIframeHeight();
                                                    this.props.onSubmit({...invoice, code: invoice.code?.toLowerCase()});
                                                }}}
                                            disabled={isDisabled(invoice)}
                                        >Versturen
                                        </Mary.atoms.button.Button>
                                    </Mary.base.Div>
                                </>
                            ) : (
                                <>
                                    <Mary.molecules.ButtonsWrapper
                                        orientations={{ [Mary.theme.ThemeBreakpoints.XS]: Mary.molecules.Orientation.HORIZONTAL}}
                                        alignment={Mary.molecules.Alignment.RIGHT}
                                    >
                                        {this.hasErrorOrInvalid() ? (
                                            <Mary.base.Div style={{display: "flex"}}>
                                                <Mary.atoms.button.Button
                                                    className="scl-a-btn--big"
                                                    theme={{
                                                        palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                                    }}
                                                    link={{
                                                        onClick: () =>
                                                            this.retry(),
                                                    }}
                                                >Probeer opnieuw
                                                </Mary.atoms.button.Button>
                                            </Mary.base.Div>) : (
                                            <Mary.base.Div style={{display: "flex"}}>
                                                <Mary.atoms.button.Button
                                                    className="scl-a-btn--big"
                                                    theme={{
                                                        palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                                    }}
                                                    link={{
                                                        onClick: () => window.location.reload(),
                                                    }}
                                                >Ga verder
                                                </Mary.atoms.button.Button>
                                            </Mary.base.Div>
                                        )}
                                    </Mary.molecules.ButtonsWrapper>
                                </>
                            )}
                        </>)}
            </>
        );
    }

    private showError() {
        const getErrorMessage = (s?: string) => {
            if (s === "Invalid code or contract number.") {
                return "Ongeldige afhaalcode of contractnummer.";
            }

            return s ?? "Er is iets misgegaan.";
        };
        if (this.hasErrorOrInvalid() && this.props.results?.error) {
            return (
                <>
                    <Mary.atoms.Blockquote theme={{paletteState: Mary.theme.ThemePaletteState.DANGER}}>
                        <Mary.organisms.RTE>
                            <span style={{ color: "var(--scl-color-brand--text-body)"}}>{(this.props.results?.error as ErrorInfoObject[])?.map?.(m => (<><b>{m.message} ({m.code})</b><br/></>)) ?? getErrorMessage(this.props.results?.error.toString())}</span>
                        </Mary.organisms.RTE>
                    </Mary.atoms.Blockquote>
                    <br/>
                </>);
        }
        return null;
    }

    private retry() {
        this.props.clearResults();
        this.setState({
            invoice: this.state.invoice,
            errors: this.state.errors,
            status: FormStatus.INIT_UPLOAD,
        });
    }

    private hasErrorOrInvalid(): boolean {
        return this.props.results?.status === ResponseStatus.INVALID || this.hasError();
    }

    private showSummary() {
        return !this.hasErrorOrInvalid() && !!this.props.results;
    }

    private hasError(): boolean {
        return this.props.results?.status === ResponseStatus.ERROR || this.props.results?.statusCode === 400 ||  this.props.results?.statusCode === 404 || this.props.results?.statusCode === 403;
    }

    private showForm() {
        return this.props.results?.status === ResponseStatus.INVALID || !this.props.results;
    }

    private updateStore = (invoice?: FormInvoiceUploadObject, errorInput?: FormInvoiceUploadErrorObject) => {
        const errors: FormInvoiceUploadErrorObject = errorInput || {} as FormInvoiceUploadErrorObject;

        this.setState({
            invoice: {
                ...this.state.invoice,
                ...invoice,
            },
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };
}

export const Invoice = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvoiceComp);
