/* eslint-disable max-len */
import { FormStatus } from "./_Types";
import React from "react";
import { Header } from "../../01-sections/Header";


interface Props {
    status?: FormStatus;
    deliveredOnline?: boolean;
    tab?: string;
}

export const PageHeader: React.FunctionComponent<Props> = ({ status, deliveredOnline, tab }) => {
    switch (true) {
    case (status === FormStatus.SUCCESS && deliveredOnline && tab === "register" ):
        return (
            <span style={{ whiteSpace: "pre-line"}}>
                <Header
                    title="Fiets is aangemeld"
                    description={
                        `Bedankt voor de aanmelding. Je kunt direct je factuur bijvoegen via onderstaande button "Factuur bijvoegen". Dit kan ook later nog via de link die je ontvangt in de bevestigingsmail van deze aanmelding.
                        
                        Wil je nog een fiets aanmelden? Klik dan op de button "Ga verder".`
                    }
                />
            </span>);
    case (status === FormStatus.SUCCESS && !deliveredOnline && tab === "register" ):
        return (
            <Header
                title="Fiets is aangemeld"
                description={
                    "Bedankt voor de aanmelding. Wil je nog een fiets aanmelden? Klik dan op onderstaande button."
                }
            />);
    case (status === FormStatus.SUCCESS && tab === "upload-invoice" ):
        return (
            <span style={{ whiteSpace: "pre-line"}}>
                <Header
                    title="Factuur succesvol ontvangen"
                    description={
                        `We hebben jouw factuur succesvol ontvangen.
                        
                        Wij zullen de factuur in behandeling nemen en verwerken.

                        Mocht je in de tussentijd vragen hebben, aarzel dan niet om contact op te nemen met Team Bikes & New Mobility via telefoonnummer 033 454 9174 (bereikbaar van maandag t/m vrijdag tussen 09:00 - 17:00 uur) of via e-mail naar fietsen@vwpfs.nl.`
                    } />
            </span>
        );
    case (status === FormStatus.INIT_REGISTER):
        return (
            <Header
                title="Aanmelden private lease fiets"
                description={
                    "Met onderstaand formulier kun je een fiets eenvoudig en snel aanmelden."
                }
            />
        );
    case (status === FormStatus.INIT_UPLOAD):
        return (
            <Header
                title="Factuur bijvoegen private lease fiets"
                description={
                    "Met onderstaand formulier kun je de factuur uploaden."
                }
            />
        );
    case (status === FormStatus.ERROR && tab === "register"):
        return (
            <span style={{ whiteSpace: "pre-line"}}>
                <Header
                    title="Aanmelding is mislukt"
                    description={
                        `Het is niet gelukt om de fiets aan te melden. Controleer of je de gegevens juist hebt ingevuld. Krijg je weer deze melding? Neem dan contact op met Team Bikes & New Mobility via telefoonnummer 033 4549174 (bereikbaar van maandag t/m vrijdag tussen 09:00 - 17:00 uur) of via e-mail naar fietsen@vwpfs.nl.
                        
                        Klik op onderstaande button om opnieuw te beginnen.`
                    }
                /></span>);
    case (status === FormStatus.ERROR && tab === "upload-invoice"):
        return (
            <span style={{ whiteSpace: "pre-line"}}>
                <Header
                    title="Factuur bijvoegen is mislukt"
                    description={
                        `Het is niet gelukt om jouw factuur te uploaden. We willen je vragen om het opnieuw te proberen. Krijg je weer deze melding? Neem dan contact op met Team Bikes & New Mobility via telefoonnummer 033 4549174 (bereikbaar van maandag t/m vrijdag tussen 09:00 - 17:00 uur) of via e-mail naar fietsen@vwpfs.nl.
                                    
                        Klik op onderstaande button om opnieuw te beginnen.`
                    }
                /></span>);
    default:
        return <></>;
    }
};
