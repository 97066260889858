/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { ErrorInfoObject, FormInvoiceUploadErrorObject, FormRegisterPrivateLeaseBikeObject, FormRegisterPrivateLeaseBikeObjectResponse, RegisterPrivateLeaseBikeValidations, ResponseStatus } from "../../../../../store/RegisterPrivateLeaseBike/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { PageHeader } from "../../02-pages/RegisterPrivateLeaseBike/Header";
import { Summary } from "../../02-pages/RegisterPrivateLeaseBike/Summary";
import { FormStatus } from "../../02-pages/RegisterPrivateLeaseBike/_Types";
import * as RegisterPLBike from "../../02-pages/RegisterPrivateLeaseBike/index";
import { hasLengthValidation } from "../../../07-organisms/Form/Field.utils";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { PageTabs } from "../../../../../store/AppDisplays";
import { TabsContext } from "../TabsContext";

interface State {
    form: FormRegisterPrivateLeaseBikeObject;
    errors: FormInvoiceUploadErrorObject;
    validations: RegisterPrivateLeaseBikeValidations;
    status: FormStatus;
    deliveredOnline?: boolean;
}

interface OwnProps {
    asPage: boolean;
}

interface StateProps {
    results?: FormRegisterPrivateLeaseBikeObjectResponse;
    loading: boolean;
}

/**
 *
 */
interface DispatchProps {
    form: (body: FormRegisterPrivateLeaseBikeObject) => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    results: s.prop("remote").prop(RemoteScope.REGISTER_PRIVATE_LEASE_BIKE),
    loading: isRemoteLoading(s, RemoteScope.REGISTER_PRIVATE_LEASE_BIKE),
});


/**
 *
 */
const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    form: (body) =>
        dispatch(remoteTrigger(RemoteScope.REGISTER_PRIVATE_LEASE_BIKE, body)),
    clearResults: () => (
        (
            dispatch(remoteClearResponse(RemoteScope.REGISTER_PRIVATE_LEASE_BIKE)),
            dispatch(remoteClearError(RemoteScope.REGISTER_PRIVATE_LEASE_BIKE))
        )),
});

class RegisterComp
    extends React.Component<Props, State> {

    static contextType = TabsContext;

    public constructor(props: Props) {
        super(props);

        this.state = {
            form: {},
            errors: {},
            validations: {},
            status: FormStatus.INIT_REGISTER,
        };
        this.setDeliveredOnline = this.setDeliveredOnline.bind(this);
        this.clear = this.clear.bind(this);
        this.retry = this.retry.bind(this);
        this.updateStore =  this.updateStore.bind(this);
        this.handleFactuurBijvoegenClick = this.handleFactuurBijvoegenClick.bind(this);
    }

    public componentDidMount() {
        this.props.clearResults();
    }

    public componentDidUpdate(_prev: Props) {
        if (this.hasErrorOrInvalid() && this.state.status !== FormStatus.ERROR) {
            this.setState({
                ...this.state,
                status: FormStatus.ERROR,
            });
        } else if ((this.showSummary()) && this.state.status !== FormStatus.SUCCESS) {
            this.setState({
                ...this.state,
                status: FormStatus.SUCCESS,
            });
        }
    }


    public render() {
        return (
            <>
                {this.props.loading ?
                    (
                        <Mary.atoms.loadingindicator.LoadingIndicator
                            type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                            theme={{ palette: Mary.theme.ThemePalette.BRAND_PRIMARY }}
                        />
                    ) : (
                        <>
                            {<PageHeader status={this.state.status} deliveredOnline={this.state.deliveredOnline} tab="register"/>}
                            {this.showError()}
                            {this.showForm() ? (
                                <RegisterPLBike.Form
                                    updateStore={this.updateStore}
                                    onClear={this.clear}
                                    onSubmit={this.props.form}
                                    form={this.state.form}
                                    errors={this.state.errors}
                                    validations={this.state.validations}
                                    setDeliveredOnline={this.setDeliveredOnline}
                                    handleInputChange={this.context.handleInputChange}
                                />
                            ) : (
                                <>
                                    {this.showSummary() && (
                                        <Summary
                                            data={this.props.results as FormRegisterPrivateLeaseBikeObjectResponse}
                                        />
                                    )}
                                    <Mary.molecules.ButtonsWrapper
                                        orientations={{ [Mary.theme.ThemeBreakpoints.XS]: Mary.molecules.Orientation.HORIZONTAL}}
                                        alignment={Mary.molecules.Alignment.RIGHT}
                                    >
                                        {this.hasErrorOrInvalid() ? (
                                            <Mary.atoms.button.Button
                                                className="scl-a-btn--big"
                                                theme={{
                                                    palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                                }}
                                                link={{
                                                    onClick: () =>
                                                        this.retry(),
                                                }}
                                            >Probeer opnieuw
                                            </Mary.atoms.button.Button>
                                        ) : (
                                            <>
                                                {this.state.deliveredOnline &&
                                                <Mary.base.Div style={{display: "flex"}}>
                                                    <Mary.atoms.button.Button
                                                        className="scl-a-btn--big"
                                                        theme={{
                                                            palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                                        }}
                                                        link={{
                                                            onClick: this.handleFactuurBijvoegenClick,
                                                        }}
                                                    >Factuur bijvoegen
                                                    </Mary.atoms.button.Button>
                                                </Mary.base.Div>}
                                                <Mary.base.Div style={{display: "flex"}}>
                                                    <Mary.atoms.button.Button
                                                        className="scl-a-btn--big"
                                                        theme={{
                                                            palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                                        }}
                                                        link={{
                                                            onClick: () => window.location.reload(),
                                                        }}
                                                    >Ga verder
                                                    </Mary.atoms.button.Button>
                                                </Mary.base.Div>
                                            </>)}
                                    </Mary.molecules.ButtonsWrapper>
                                </>

                            )}
                        </>
                    )}
            </>
        );
    }

    private updateStore = (form?: FormRegisterPrivateLeaseBikeObject, errorInput?: FormInvoiceUploadErrorObject) => {
        const errors: FormInvoiceUploadErrorObject = errorInput || {} as FormInvoiceUploadErrorObject;
        const validations: RegisterPrivateLeaseBikeValidations = {};

        if (form) {
            Object.keys(form).forEach(k => {
                if (k === "emailAddressDealer") {
                    validations[k] = Mary.organisms.hasEmailValidation(form[k] as string | number ?? "", "");
                    return;
                }
                if (k === "code") {
                    validations[k] = hasLengthValidation(form[k] as string | number ?? "", "", 9);
                    return;
                }
                validations[k] = Mary.organisms.hasValueValidation(form[k] as string | number ?? "", "");
            });
        }

        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                ...form,
            },
            errors: {
                ...this.state.errors,
                ...errors,
            },
            validations: {
                ...this.state.validations,
                ...validations,
            },
        });
    };

    private showError() {
        const getErrorMessage = (s?: string) => {
            if (s === "Invalid code or contract number.") {
                return "Ongeldige afhaalcode of contractnummer.";
            }

            return s ?? "Er is iets misgegaan.";
        };
        if (this.hasErrorOrInvalid() && this.props.results?.error) {
            return (
                <>
                    <Mary.atoms.Blockquote theme={{paletteState: Mary.theme.ThemePaletteState.DANGER}}>
                        <Mary.organisms.RTE>
                            <span style={{ color: "var(--scl-color-brand--text-body)"}}>{(this.props.results?.error as ErrorInfoObject[])?.map?.(m => (<><b>{m.message} ({m.code})</b><br/></>)) ?? getErrorMessage(this.props.results?.error.toString())}</span>
                        </Mary.organisms.RTE>
                    </Mary.atoms.Blockquote>
                    <br/>
                </>);
        }
        return null;
    }

    private setDeliveredOnline(deliveredOnline: boolean) {
        this.setState({ deliveredOnline: deliveredOnline});
    }

    private hasErrorOrInvalid(): boolean {
        return this.props.results?.status === ResponseStatus.INVALID || this.hasError();
    }

    private showSummary() {
        return !this.hasErrorOrInvalid() && !!this.props.results;
    }

    private hasError(): boolean {
        return this.props.results?.status === ResponseStatus.ERROR || this.props.results?.statusCode === 400 ||  this.props.results?.statusCode === 404 || this.props.results?.statusCode === 403;
    }

    private showForm() {
        return this.props.results?.status === ResponseStatus.INVALID || !this.props.results;
    }

    private clear() {
        this.props.clearResults();
        this.setState({
            form: {},
            errors: {},
            validations: {},
            status: FormStatus.INIT_REGISTER,
        });
    }

    private retry() {
        this.props.clearResults();
        this.setState({
            form: this.state.form,
            errors: this.state.errors,
            validations: this.state.validations,
            status: FormStatus.INIT_REGISTER,
        });
    }

    private handleFactuurBijvoegenClick() {
        const { changeTab, handleBtnClick } = this.context;
        if (changeTab) {
            handleBtnClick();
            changeTab(PageTabs.ATTACH_INVOICE);
        }
    }
}

export const Register = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RegisterComp);

